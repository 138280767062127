import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import Markdown from 'markdown-to-jsx';

// Components
import { FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, CrossCountryChampionsMeetNameTable } from 'templates';

// Types
import { MeetName, Record, Season } from 'types';

interface Props {
  data: {
    champions: {
      nodes: {
        data: Record;
      }[];
    };
    pageContent: any;
  };
  pageContext: {
    meetName: MeetName;
    season: Season;
  };
}

const FILTERS = [
  { title: 'Year', gqlKey: 'year' },
  { title: 'Division', gqlKey: 'division' },
  { title: 'Venue', gqlKey: 'venue' },
  { title: 'Gender', gqlKey: 'gender' },
  { title: 'Team Champion', gqlKey: 'team_champion' },
];

const CrossCountryChampionsTablePage = (props: Props) => {
  const { meetName } = props.pageContext;
  const { champions, pageContent } = props.data;
  const markdown = get(pageContent, 'nodes[0].data.markdown_content', '') || '';
  // const sponsors = get(pageContent, 'nodes[0].data.sponsors');
  // const hasSponsors = sponsors && sponsors.length > 0;

  // GQL only allows for one sort. Receive the data sorted by division,
  // then sort it by year too.
  const orderedChampions = {
    nodes: orderBy(champions.nodes, ['data.year'], ['asc']),
  };

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate templateTitle={`Cross Country ${meetName} Champions`}>
          <>
            <Markdown>{markdown}</Markdown>
            <FilterBar
              data={orderedChampions}
              filters={FILTERS}
              render={(tableData) => (
                <CrossCountryChampionsMeetNameTable data={tableData} />
              )}
            />
          </>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default CrossCountryChampionsTablePage;

export const query = graphql`
  query ($meetName: String) {
    pageContent: allAirtable(filter: { table: { eq: "Champion Table Page" } }) {
      nodes {
        data {
          markdown_content
          sponsors {
            data {
              sponsor_name
              sponsor_url
              attachments {
                localFiles {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    champions: allAirtable(
      filter: {
        table: { eq: "Cross Country Champions" }
        data: { meet_name: { eq: $meetName } }
      }
      sort: { fields: data___division, order: ASC }
    ) {
      nodes {
        data {
          year
          division
          result
          team
          champion
          individual_champion
          team_champion
          points
          gender
          meet_name
          venue
        }
      }
    }
  }
`;
